export class Staff {
  constructor(id, name, blurb, position, profileImg, slug) {
    this.id = id;
    this.name = name;
    this.blurb = blurb;
    this.position = position;
    this.profileImg = profileImg;
    this.slug = slug;
  }
}
